import React from 'react';
import styled from 'styled-components';

import Text from '@/atoms/Text';
import WithArrow from '@/atoms/WithArrow';

import theme from '@/styles/theme';

const List = styled(Text).attrs({ as: 'ul' })`
  list-style: none;
`;

export default ({ links, bottomText, bottomLink, ...props }) => {
  return (
    <>
      <List {...props}>
        {links.map(link => (
          <li key={link.label}>
            <a href={link.url} target="_blank" rel="noopener noreferrer">
              <WithArrow>{link.label}</WithArrow>
            </a>
          </li>
        ))}
      </List>

      {bottomText && (
        <Text pt={theme.spacing.lhPS} {...props}>
          {bottomText}
          <br />
          {bottomLink && (
            <a href={bottomLink.url} target="_blank" rel="noopener noreferrer">
              {bottomLink.label}
            </a>
          )}
        </Text>
      )}
    </>
  );
};
