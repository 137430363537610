import React from 'react';

import Grid from '@/atoms/Grid';

export default props => (
  <Grid.Item
    $display="flex"
    $flexDirection="column"
    $justifyContent="space-between"
    $minHeight={['auto', 'auto', 12.5]}
    {...props}
  />
);
