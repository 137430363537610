/**
 * Get Uri based on content type
 */
const PREFIXES = {
  page: null,
  project: 'work',
  job: 'career',
};

export const getUri = (slug, type = 'page') => {
  const parts = [PREFIXES[type], slug].filter(Boolean);

  return `/${parts.join('/')}`;
};

/**
 * Build the project overview URI based on the active category and view
 */
export const getProjectOverviewUri = (category, view) => {
  const parts = [
    category ? `category=${category}` : false,
    view === 'list' ? 'view=list' : false,
  ].filter(Boolean);

  return parts.length ? `/?${parts.join('&')}` : '/';
};

/**
 * Get query params from search string
 */
export const getQueryParams = searchString => {
  const pairs = searchString.replace('?', '').split('&');

  return pairs.reduce((acc, cur) => {
    const [key, value] = cur.split('=');

    if (key && value && key.length > 0 && value.length > 0) {
      return {
        ...acc,
        [key]: value,
      };
    }

    return acc;
  }, {});
};
