import React from 'react';

import { Link } from 'gatsby';

import Grid from '@/atoms/Grid';
import Text from '@/atoms/Text';

import { getUri } from '@/utils/routing';

export default ({ privacyPolicyPage, ...props }) => {
  const today = new Date();

  return (
    <Grid.Item w={[1, 1, 3.5 / 12]} pb={0.125} $alignSelf="flex-end">
      <Text t={-2} $color="gray" {...props}>
        © {today.getFullYear()} NR2154
      </Text>

      {privacyPolicyPage && (
        <Text t={-2} $color="gray" {...props}>
          <Link to={getUri(privacyPolicyPage.slug.current, 'page')}>
            {privacyPolicyPage.title}
          </Link>
        </Text>
      )}
    </Grid.Item>
  );
};
