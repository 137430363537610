import React from 'react';
import styled from 'styled-components';

import useViewportHeight from '@/hooks/useViewportHeight';

import Box from '@/atoms/Box';

import theme from '@/styles/theme';

const Container = styled(Box)`
  position: relative;
  width: 100%;
  min-height: ${props => (props.$vh ? `${props.$vh}px` : '100vh')};

  ${props =>
    props.scrollSnap &&
    `
    height: ${props.$vh ? `${props.$vh}px` : '100vh'};
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    -webkit-overflow-scrolling: touch;
  `}
`;

export default ({
  withPaddingTop = true,
  withScrollSnap = false,
  ...props
}) => {
  // workaround for mobile devices calculating 100vh without browser chrome
  const [vh] = useViewportHeight();

  return (
    <Container
      pt={withPaddingTop ? theme.spacing.contentTop : 0}
      scrollSnap={withScrollSnap}
      $vh={vh}
      {...props}
    />
  );
};
