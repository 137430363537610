import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import Box from '@/atoms/Box';
import Input from '@/atoms/Input';
import Text from '@/atoms/Text';

import { validateEmail } from '@/utils/helpers';
import { scale } from '@/styles/utils';
import theme from '@/styles/theme';

const Form = styled(Box).attrs({ as: 'form' })`
  position: relative;
`;

const Submit = styled(Text).attrs({ as: 'button' })`
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  padding: ${scale(0.5)} 0;
  background: transparent;
  -webkit-appearance: none;
  outline: none;
  cursor: ${props => (props.isVisible ? 'pointer' : 'default')};
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: all 0.1s ease-out;

  :hover,
  :focus {
    color: ${theme.colors.gray};
  }
`;

const SuccessMessage = styled(Text)`
  position: absolute;
  right: 0;
  top: ${scale(0.5)};
  opacity: ${props => (props.isVisible ? 1 : 0)};
  pointer-events: none;
  transition: all 0.1s ease-out;
`;

const ErrorMessage = styled(Text).attrs({ t: -2 })`
  position: absolute;
  left: 0;
  top: ${scale(3)};
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: all 0.1s ease-out;
`;

export default ({ subscribe, status, message, ...props }) => {
  const emailField = useRef(null);
  const [isValidEmail, setValidEmail] = useState(true);

  const handleSubmit = e => {
    e.preventDefault();
    const email = emailField.current.value;

    if (email && validateEmail(email)) {
      setValidEmail(true);

      subscribe({ EMAIL: email });
    } else {
      setValidEmail(false);
    }
  };

  let errorMessage = null;

  if (!isValidEmail) {
    errorMessage = 'Please enter a valid email address';
  } else if (status === 'error') {
    errorMessage = message;
  }

  return (
    <Form onSubmit={handleSubmit} {...props}>
      <Input
        ref={emailField}
        type="email"
        placeholder="Enter your email"
        disabled={['sending', 'success'].includes(status)}
        pr={11.5}
      />

      <Submit
        type="submit"
        disabled={['sending', 'success'].includes(status)}
        isVisible={status !== 'success'}
      >
        Newsletter subscribe
      </Submit>

      <SuccessMessage isVisible={status === 'success'}>
        Thank you
      </SuccessMessage>

      <ErrorMessage
        isVisible={errorMessage}
        dangerouslySetInnerHTML={{ __html: errorMessage }}
      />
    </Form>
  );
};
