import styled from 'styled-components';

import Text from '@/atoms/Text';

import { scale } from '@/styles/utils';
import theme from '@/styles/theme';

export default styled(Text).attrs({ as: 'input' })`
  border: 1px solid ${theme.colors.gray};
  border-width: 0 0 1px;
  border-radius: 0;
  width: 100%;
  padding-top: ${scale(0.5)};
  padding-bottom: calc(${scale(0.5)} - 1px);
  color: ${theme.colors.black};
  background: transparent;
  -webkit-appearance: none;
  outline: none;
  transition: all 0.15s ease-out;

  :focus {
    border-color: ${theme.colors.black};
  }

  ::placeholder {
    color: ${theme.colors.gray};
  }
`;
