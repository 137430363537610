import React from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import Form from './Form';

export default ({ ...props }) => (
  <MailchimpSubscribe
    url={process.env.GATSBY_MAILCHIMP_SUBSCRIBE_URL}
    render={mailchimpProps => <Form {...props} {...mailchimpProps} />}
  />
);
