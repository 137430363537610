import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import useViewportHeight from '@/hooks/useViewportHeight';

import Block from './Block';
import Contact from './Contact';
import Legal from './Legal';
import Grid from '@/atoms/Grid';
import Text from '@/atoms/Text';
import NewsletterForm from '@/components/NewsletterForm';

import { mapToSingleObject } from '@/utils/helpers';
import { media } from '@/styles/utils';

const Footer = styled(Grid.Container)`
  min-height: ${props => (props.$vh ? `${props.$vh}px` : '100vh')};
  scroll-snap-align: end;
  scroll-snap-stop: always;

  ${media.md`
    min-height: auto;
  `}
`;

export default props => {
  const [viewportHeight] = useViewportHeight();

  const { data } = useStaticQuery(graphql`
    query {
      data: allSanitySiteSettingsInformation {
        edges {
          node {
            companyAddressNYC
            companyAddressCPH
            companyAddressPAR
            contactLinksMobile {
              label
              url
            }
            contactLinksDesktop {
              label
              url
            }
            privacyPolicyPage {
              title
              slug {
                current
              }
            }
            newBusinessContact {
              label
              url
            }
          }
        }
      }
    }
  `);

  const information = mapToSingleObject(data);

  return (
    <Footer
      as="footer"
      pt={[6, 6, 4]}
      pb={[2, 2, 4.5]}
      bg="grayLight"
      $vh={viewportHeight}
      $display={['flex', 'flex', 'block']}
      {...props}
    >
      <Grid
        $flexDirection={['column', 'column', 'row']}
        $justifyContent={['flex-end', 'flex-end', 'flex-start']}
        w={1}
      >
        <Block w={[1, 1, 3.5 / 12]} pb={[1.5, 1.5, 0]}>
          <Text whiteSpace="pre-line">{information.companyAddressNYC}</Text>
        </Block>

        <Block w={[1, 1, 3.5 / 12]} pb={[1.5, 1.5, 0]}>
          <Text whiteSpace="pre-line">{information.companyAddressCPH}</Text>
        </Block>

        <Block w={[1, 1, 3.5 / 12]} pb={[1.5, 1.5, 0]}>
          <Text whiteSpace="pre-line">{information.companyAddressPAR}</Text>
        </Block>

        <Block
          w={[1, 1, 1.5 / 12]}
          $display={['none', 'none', 'flex']}
          $alignItems="flex-end"
          pb={[1.5, 1.5, 0]}
        >
          <Text>
            <a
              href={information.newBusinessContact.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {information.newBusinessContact.label}
            </a>
          </Text>
        </Block>

        <Block
          w={[1]}
          $display={['flex', 'flex', 'none']}
          pt={[3, 3, 0]}
          pb={[6, 6, 0]}
        >
          <Contact links={information.contactLinksMobile} t="h2" />
        </Block>

        <Grid.Item
          w={[1, 1, 3.5 / 12]}
          $display={['none', 'none', 'flex']}
          $order={['unset', 'unset', 20]}
          $alignSelf="flex-end"
        >
          <Contact links={information.contactLinksDesktop} />
        </Grid.Item>

        <Grid.Item
          w={[1, 1, 5 / 12]}
          pb={[6, 6, 0]}
          $order={['unset', 'unset', 30]}
          $alignSelf="flex-end"
        >
          <NewsletterForm mb={[0, 0, -0.375]} />
        </Grid.Item>

        <Legal privacyPolicyPage={information.privacyPolicyPage} />
      </Grid>
    </Footer>
  );
};
